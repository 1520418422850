import React from 'react';
import styled from 'styled-components';

interface Props {
  maxWidth?: string;
  children?: any;
}

const InputGroup: React.FunctionComponent<Props> = (props: Props) => {

  return (
    <Container
      maxWidth={ props.maxWidth }>
      { props.children }
    </Container>
  );

};

export default InputGroup;

const Container = styled.div<{ maxWidth?: string }>`
  margin-bottom: 20px;
  max-width: ${ (props) => props.maxWidth ? props.maxWidth : 'unset' };
  
  &:last-child {
    margin-bottom: 0;
  }
`;
