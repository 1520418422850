import { Action, applyMiddleware, combineReducers, createStore, Reducer } from '@reduxjs/toolkit';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import onboarding from './onboarding/reducer';
import job from './job/reducer';
import popup from './popup/reducer';

const appReducer: Reducer = combineReducers({
  job,
  onboarding,
  popup
});

const rootReducer = (state: any, action: Action): Reducer => {
  return appReducer(state, action);
};

export const Store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));

export type ReduxState = ReturnType<ReturnType<typeof rootReducer>>;
