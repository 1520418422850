export enum ActionsEnum {
  // Popup
  SET_ERROR_MESSAGE = 'Popup/Set error message',
  REMOVE_ERROR_MESSAGE = 'Popup/Remove error message',
  SET_SUCCESS_MESSAGE = 'Popup/Set success message',
  REMOVE_SUCCESS_MESSAGE = 'Popup/Remove success message',
  // Jobs
  RETRIEVE_JOB_DETAILS = 'Jobs/Retrieve Job Details',
  // Onboarding
  SET_CV = 'Onboarding/Set CV',
  UNSET_CV = 'Onboarding/Unset CV',
  SUBMIT_STEP_ONE = 'Onboarding/Submit Step One',
  SUBMIT_STEP_TWO = 'Onboarding/Submit Step Two',
  SUBMIT_STEP_THREE = 'Onboarding/Submit Step Three',
}
