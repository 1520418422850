import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { BASE_URL } from "../constants/endpoints";

const instance: AxiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

export const setAuthorizationToken = (token: string|null = null): void => {
  if (token && token.trim()) {
    instance.defaults.headers.common['Authorization'] = `Bearer ${ token }`;
  } else {
    delete instance.defaults.headers.common['Authorization'];
  }
};

export const setAuthInterceptor = (): boolean => {
  console.log('[setAuthInterceptor] Bootstrap jwt interceptor...')
  let returnError = false;
  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      const { status, data } = error.response;
      if (
        (status === 401 && data && data.data && data.data.type === 'TokenExpiredException') ||
        (status === 403 && data && data.data && data.data.type === 'InvalidSignatureException')
      ) {
        localStorage.clear();
        returnError = true;
      }
      return Promise.reject(error);
  });
  return returnError;
};

export const Axios = axios;

export const http = async (endpoint: string, config: AxiosRequestConfig): Promise<any> => instance.request({ url: endpoint, ...config });
