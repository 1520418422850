import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Routes from '../../routes';
import NotFound from '../../pages/NotFound/NotFound';
import Onboarding from '../../pages/Onboarding/Onboarding';
import OnboardingStepOne from '../../pages/Onboarding/steps/OnboardingStepOne/OnboardingStepOne';
import OnboardingStepTwo from '../../pages/Onboarding/steps/OnboardingStepTwo/OnboardingStepTwo';
import OnboardingStepThree from '../../pages/Onboarding/steps/OnboardingStepThree/OnboardingStepThree';
import Greetings from '../../pages/Onboarding/steps/Greetings/Greetings';

const Router: React.FunctionComponent = () => {

  return (
    <Switch>
      <Route exact path={ Routes.ONBOARDING } component={ Onboarding } />
      <Route exact path={ Routes.STEP_1 } component={ OnboardingStepOne } />
      <Route exact path={ Routes.STEP_2 } component={ OnboardingStepTwo } />
      <Route exact path={ Routes.STEP_3 } component={ OnboardingStepThree } />
      <Route exact path={ Routes.GREETINGS } component={ Greetings } />
      <Route path="*" component={ NotFound } />
    </Switch>
  );

};

export default Router;
