import React from 'react';

const MapCursorIcon: React.FunctionComponent = () => {

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.9629 10.23C19.9629 16.4233 12 21.7319 12 21.7319C12 21.7319 4.03711 16.4233 4.03711 10.23C4.03711 8.11809 4.87605 6.0927 6.36939 4.59937C7.86272 3.10603 9.88811 2.26709 12 2.26709C14.1119 2.26709 16.1373 3.10603 17.6306 4.59937C19.1239 6.0927 19.9629 8.11809 19.9629 10.23Z"
        stroke="#F86F6F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"/>
      <path
        d="M12.0005 12.8838C13.4664 12.8838 14.6548 11.6954 14.6548 10.2295C14.6548 8.76357 13.4664 7.5752 12.0005 7.5752C10.5346 7.5752 9.34619 8.76357 9.34619 10.2295C9.34619 11.6954 10.5346 12.8838 12.0005 12.8838Z"
        stroke="#F86F6F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"/>
    </svg>
  );

};

export default MapCursorIcon;
