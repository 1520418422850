const fonts = {
  primary: "'Manrope', sans-serif",
  secondary: "'Manrope', sans-serif"
};

const colors = {
  primary: '#4B4DF7',
  violet: '#9393C0'
};

const Theme = {
  background: {
    color: '#FFFFFF'
  },
  typography: {
    default: {
      fontSize: '1rem',
      fontWeight: 500,
      fontFamily: fonts.primary
    },
    heading: {
      fontSize: {
        h1: '2rem',
        h2: '1.5rem',
        h3: '1.17rem',
        h4: '1rem',
        h5: '.83rem',
        h6: '.67rem'
      },
      fontFamily: fonts.primary,
      fontWeight: 800,
      color: '#1C1B26',
    },
    subtitles: {
      fontSize: 'calc(1rem + 12.5%)',
      fontFamily: fonts.primary,
      fontWeight: 600,
      color: colors.violet
    },
    labels: {
      fontSize: 'calc(1rem - 25%)',
      fontFamily: fonts.secondary,
      fontWeight: 600,
      color: '#999999'
    },
    text: {
      fontSize: 'calc(1rem - 12.5%)',
      fontFamily: fonts.secondary,
      fontWeight: 500,
      color: '#373737'
    }
  },
  ui: {
    company: {
      color: colors.primary
    },
    progressBar: {
      color: colors.primary
    },
    goBack: {
      color: colors.violet,
      borderColor: '#E0E0F0'
    },
    buttons: {
      primary: {
        backgroundColor: colors.primary,
        color: '#FFFFFF',
        fontSize: 'calc(1rem + 12.5%)',
        fontWeight: 400,
      }
    },
    input: {
      color: '',
      backgroundColor: '#F3F5FA'
    }
  },
  popupMessage: {
    success: {
      backgroundColor: '#06D2A1',
      color: '#FFFFFF',
      boxShadow: '0 8px 10px rgba(8, 212, 163, 0.3)',
      border: '1px solid #04D4C7'
    },
    error: {
      backgroundColor: 'rgba(232, 53, 53, 0.8)',
      color: '#FFFFFF',
      boxShadow: '0 8px 10px rgba(240, 27, 27, 0.3)',
      border: '1px solid #e21717'
    },
    info: {
      backgroundColor: '#04D4C7',
      color: '#FFFFFF',
      boxShadow: '0 8px 10px rgba(4, 212, 199, 0.3)',
      border: '1px solid #0499D4'
    }
  }
};

export default Theme;
