import React from 'react';
import styled from 'styled-components';

interface Props {
  margin?: string;
  color?: string;
  children: any;
}

const Small: React.FunctionComponent<Props> = ({ margin, color, children }) => {

  return (
    <Text
      margin={ margin }
      color={ color }>
      { children }
    </Text>
  );

};

export default Small;

const Text = styled.small<{ margin?: string, color?: string }>`
  font-size: calc(${ (props) => props.theme.typography.default.fontSize } - 20%);
  font-weight: ${ (props) => props.theme.typography.default.fontWeight };
  font-family: ${ (props) => props.theme.typography.default.fontFamily };
  margin: ${ (props) => props.margin ? props.margin : '1rem 0' };
  color: ${ (props) => props.color ? props.color : 'inherit' };
`;
