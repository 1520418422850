import { createReducer } from '@reduxjs/toolkit';
import { removeErrorMessage, removeSuccessMessage, setErrorMessage, setSuccessMessage } from './actions';

export interface PopupState {
  error: string|null;
  success: string|null;
}

const PopupInitialState: PopupState = {
  error: null,
  success: null
};

const reducerPopupMessage = createReducer<PopupState>(PopupInitialState, {
  [ setErrorMessage.type ]: (state, action) => ({
    ...state,
    error: action.payload
  }),
  [ removeErrorMessage.type ]: (state, action) => ({
    ...state,
    error: null
  }),
  [ setSuccessMessage.type ]: (state, action) => ({
    ...state,
    success: action.payload
  }),
  [ removeSuccessMessage.type ]: (state, action) => ({
    ...state,
    success: null
  })
});

export default reducerPopupMessage;
