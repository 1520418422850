import React from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import classes from './InputPhone.module.scss';
import styled from 'styled-components';

interface Props {
  value: string;
  error: string;
  onChange: (phone: any) => void;
  onBlur: (phone: any) => void;
}

const InputPhone: React.FunctionComponent<Props> = ({ value, error, onChange, onBlur }) => {

  return (
    <>
      <PhoneInput
        inputProps={ { name: 'phone', autoComplete: 'off' } }
        value={ value }
        containerClass={ classes.inputContainer }
        buttonClass={ classes.inputButton }
        inputClass={ error ? `${ classes.inputPhone } ${ classes.inputError }` : classes.inputPhone }
        dropdownClass={ classes.inputDropdown }
        searchClass={ classes.inputSearch }
        country={ 'it' }
        enableSearch={ true }
        onChange={ onChange }
        onBlur={ onBlur }/>
      {
        error && (
          <ErrorMessage>
            { error }
          </ErrorMessage>
        )
      }
    </>
  );

};

export default InputPhone;

const ErrorMessage = styled.small`
  display: block;
  padding: 5px;
  color: #FE5249;
`;
