import React from 'react';
import styled from 'styled-components';

interface Props {
  name?: string;
  value?: string;
  placeholder?: string;
  disabled?: boolean;
  error?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
}

const InputText: React.FunctionComponent<Props> = (props: Props) => {

  return (
    <>
      <Input
        isInvalid={ !!props.error }
        { ...props }/>
      {
        props.error && (
          <Error>
            { props.error }
          </Error>
        )
      }
    </>
  );

};

export default InputText;

const Input = styled.input<{ isInvalid?: boolean }>`
  width: 100%;
  background-color: ${ (props) => props.theme.ui.input.backgroundColor };
  color: ${ (props) => props.theme.ui.input.color };
  outline: none;
  border-radius: 12px;
  padding: 15px;
  border: 1px solid ${ (props) => props.isInvalid ? '#FE5249' : props.theme.ui.input.backgroundColor };
  font-size: ${ (props) => props.theme.typography.default.fontSize };
`;

const Error = styled.small`
  display: block;
  padding: 5px;
  color: #FE5249;
`;
