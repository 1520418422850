import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import PrimaryButton from '../buttons/PrimaryButton/PrimaryButton';

interface Props {
  disabled: boolean;
  loading?: boolean;
  onClick?: () => any;
}

const FixedFooter = ({ disabled, loading, onClick }: Props) => {
  const { t } = useTranslation();

  return (
    <Container>
      <PrimaryButton
        disabled={disabled}
        onClick={onClick}
      >
        {!loading && t('fixedFooter.button')}
        {loading && (
          <>
            <FontAwesomeIcon
              icon={faCircleNotch}
              className="fa-spin"
            />{' '}
            {t('fixedFooter.button.loading')}
          </>
        )}
      </PrimaryButton>
    </Container>
  );
};

export default FixedFooter;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20px;
  background-color: #ffffff;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 2;
  box-shadow: 0 0 3px rgb(0 0 0 / 30%);
`;
