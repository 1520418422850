import { createSlice } from '@reduxjs/toolkit';
import { JobReducerInitialState } from '../../interfaces/states/job_reducer_initial_state.interface';
import { RETRIEVE_JOB_DETAILS } from './thunk';

const SLICE = 'Job';

const InitialState: JobReducerInitialState = {
  loading: false,
  loaded: false,
  error: false,
  job: null,
};

const slice = createSlice({
  name: SLICE,
  initialState: InitialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(RETRIEVE_JOB_DETAILS.pending, (state, action) => ({
        ...state,
        loading: true,
        loaded: false,
        error: false,
      }))
      .addCase(RETRIEVE_JOB_DETAILS.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        loaded: true,
        error: false,
        job: { ...action.payload },
      }))
      .addCase(RETRIEVE_JOB_DETAILS.rejected, (state, action) => ({
        ...state,
        loading: false,
        loaded: false,
        error: true,
      })),
});

export default slice.reducer;
