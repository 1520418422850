import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getErrorMessage, getSuccessMessage } from '../store/popup/selectors';
import { removeErrorMessage, removeSuccessMessage } from '../store/popup/actions';
const { REACT_APP_POPUP_MESSAGE_TIMEOUT } = process.env;

interface EffectReturnType {
  error: string|null;
  success: string|null;
}

const usePopupMessage = () => {

  const dispatch = useDispatch();

  const error = useSelector(getErrorMessage);
  const success = useSelector(getSuccessMessage);

  useEffect(() => {
    const timeout: ReturnType<typeof setTimeout> = setTimeout(
      () => {
        if (error) return dispatch(removeErrorMessage());
        if (success) return dispatch(removeSuccessMessage());
      },
      REACT_APP_POPUP_MESSAGE_TIMEOUT
        ? +REACT_APP_POPUP_MESSAGE_TIMEOUT
        : 3000
    );
    return () => {
      clearTimeout(timeout);
    };
  }, [ error, success, dispatch ]);

  return { error, success } as EffectReturnType;

};

export default usePopupMessage;
