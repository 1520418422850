import { ReduxState } from '../store';
import { OnboardingInterface } from '../../interfaces/states/onboarding.interface';

import { createSelector } from '@reduxjs/toolkit';

const OnboardingStore = (state: ReduxState) => state.onboarding;

export const getOnboardingLoading = createSelector(
  OnboardingStore,
  (state: OnboardingInterface) => state.loading
);

export const getOnboardingSubmitted = createSelector(
  OnboardingStore,
  (state: OnboardingInterface) => state.submitted
);

export const getOnboardingStepOneCompleted = createSelector(
  OnboardingStore,
  (state: OnboardingInterface) => state.stepOneCompleted
);

export const getOnboardingStepTwoCompleted = createSelector(
  OnboardingStore,
  (state: OnboardingInterface) => state.stepTwoCompleted
);

export const getOnboardingStepThreeCompleted = createSelector(
  OnboardingStore,
  (state: OnboardingInterface) => state.stepThreeCompleted
);

export const getOnboardingName = createSelector(
  OnboardingStore,
  (state: OnboardingInterface) => state.name
);

export const getOnboardingSurname = createSelector(
  OnboardingStore,
  (state: OnboardingInterface) => state.surname
);

export const getOnboardingPhone = createSelector(
  OnboardingStore,
  (state: OnboardingInterface) => state.phone
);

export const getOnboardingEmail = createSelector(
  OnboardingStore,
  (state: OnboardingInterface) => state.email
);

export const getOnboardingCV = createSelector(
  OnboardingStore,
  (state: OnboardingInterface) => state.cv
);
