import React from 'react';
import styled from 'styled-components';

interface Props {
  margin?: string;
  children: any;
}

const H2: React.FunctionComponent<Props> = ({ margin, children }) => {

  return (
    <Heading
      margin={ margin }>
      { children }
    </Heading>
  );

};

export default H2;

const Heading = styled.h1<{ margin?: string }>`
  font-size: ${ (props) => props.theme.typography.heading.fontSize.h2 };
  font-weight: ${ (props) => props.theme.typography.heading.fontWeight };
  font-family: ${ (props) => props.theme.typography.heading.fontFamily };
  margin: ${ (props) => props.margin ? props.margin : 0 };
`;
