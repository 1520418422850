import React from 'react';
import styled from 'styled-components';

interface Props {
  children: any;
}

const Subtitle: React.FunctionComponent<Props> = ({ children }) => {

  return (
    <Container>
      { children }
    </Container>
  );

};

export default Subtitle;

const Container = styled.p`
  margin-top: 0;
  font-size: ${ (props) => props.theme.typography.subtitles.fontSize };
  font-weight: ${ (props) => props.theme.typography.subtitles.fontWeight };
  color: ${ (props) => props.theme.typography.subtitles.color };
`;
