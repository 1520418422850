import { createAction } from '@reduxjs/toolkit';
import { ActionsEnum } from '../../enums/actions.enum';

interface IStepOneData {
  name: string;
  surname: string;
}

interface IStepTwoData {
  phone: string;
  email: string;
}

export const STEP_ONE_SUBMIT = createAction<IStepOneData>(ActionsEnum.SUBMIT_STEP_ONE);
export const STEP_TWO_SUBMIT = createAction<IStepTwoData>(ActionsEnum.SUBMIT_STEP_TWO);
export const SET_CV = createAction<File>(ActionsEnum.SET_CV);
export const UNSET_CV = createAction(ActionsEnum.UNSET_CV);
