import React from 'react';
import styled from 'styled-components';

const NotFound: React.FunctionComponent = () => {

  return (
    <Container>
      404
    </Container>
  );

};

export default NotFound;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 4rem;
  color: #4B4DF7;
  font-weight: 900;
  text-shadow: 1px 1px 5px rgb(75, 77, 247, .6);
`;
