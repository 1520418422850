import { Suspense } from 'react';
import styled from 'styled-components';
import Breakpoints from './assets/breakpoints';
import Router from './components/Router/Router';
import PopupMessage from './components/ui/PopupMessage/PopupMessage';
import usePopupMessage from './hooks/usePopupMessage';

const App = () => {
  const { success, error } = usePopupMessage();

  return (
    <AppContainer>
      <Router />
      <PopupMessage
        type="error"
        text={error}
        show={error !== null}
      />
      <PopupMessage
        type="success"
        text={success}
        show={success !== null}
      />
    </AppContainer>
  );
};

const WrappedApp = () => {
  return (
    <Suspense fallback="Loading...">
      <App />
    </Suspense>
  );
};

export default WrappedApp;

const AppContainer = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background-color: ${(props) => props.theme.background.color};
  font-size: ${(props) => props.theme.typography.default.fontSize};
  font-weight: ${(props) => props.theme.typography.default.fontWeight};
  font-family: ${(props) => props.theme.typography.default.fontFamily};

  @media screen and (min-width: ${Breakpoints.IPAD_PORTRAIT}) {
    max-width: 80%;
  }

  @media screen and (min-width: ${Breakpoints.LAPTOP}) {
    max-width: 60%;
  }
`;
