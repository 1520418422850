import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import AlgoInterviewLogo from '../../../../assets/icons/AlgoInterviewLogo';
import { CongratzImage } from '../../../../assets/icons/CongratzImage';
import Subtitle from '../../../../components/ui/Subtitle/Subtitle';
import H2 from '../../../../components/ui/typography/headings/h2';

const Greetings = () => {
  const { t } = useTranslation();

  const history = useHistory();
  const { jobPostAlias }: { jobPostAlias: string } = useParams();

  useEffect(() => {
    return () => {
      if (history.action === 'POP') {
        history.push(`/${jobPostAlias}`);
      }
    };
  });

  return (
    <Container>
      <CongratzImage />
      <H2 margin="20px 0">{t('greetings.title')}</H2>
      <Subtitle>{t('greetings.subtitle')}</Subtitle>
      <Footer>
        <FooterText>Powered By</FooterText>
        <AlgoInterviewLogo />
      </Footer>
    </Container>
  );
};

export default Greetings;

const Container = styled.div`
  padding: 0 20px;
  text-align: center;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const Footer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
`;
const FooterText = styled.div`
  color: #d1d1d1;
  font-style: italic;
`;
