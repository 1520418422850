import { useSelector } from 'react-redux';
import { getJob, getJobError, getJobLoaded, getJobLoading } from '../store/job/selectors';
import { Job } from '../interfaces/job.interface';

interface HookProps {
  loading: boolean;
  loaded: boolean;
  error: boolean;
  job: Job | null;
}

const useJob = (): HookProps => {
  const loading: boolean = useSelector(getJobLoading);
  const loaded: boolean = useSelector(getJobLoaded);
  const error: boolean = useSelector(getJobError);
  const job: Job | null = useSelector(getJob);

  return {
    loading,
    loaded,
    error,
    job,
  };
};

export default useJob;
