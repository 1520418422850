import { Delta } from 'quill';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

interface Props {
  value: Delta;
}

const JobDescriptionReader = ({ value }: Props) => {
  return (
    <ReactQuill
      readOnly
      theme="snow"
      modules={{ toolbar: null }}
      value={value}
    />
  );
};

export default JobDescriptionReader;
