import React from 'react';
import styled from 'styled-components';

interface Props {
  value: number;
}

const ProgressBar: React.FunctionComponent<Props> = ({ value }) => {

  return (
    <Container>
      <Progress
        value={ value }/>
    </Container>
  );

};

export default ProgressBar;

const Container = styled.div`
  width: 100%;
  height: 4px;
  position: relative;
  background-color: #EFF2F8;
`;

const Progress = styled.div<{ value: number }>`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: ${ (props) => props.value }%;
  background-color: ${ (props) => props.theme.ui.progressBar.color };
`;
