import React from 'react';
import styled from 'styled-components';

const Loader: React.FunctionComponent = () => {

  return (
    <Container>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        style={{
          margin: 'auto',
          background: 'transparent',
          display: 'block',
          shapeRendering: 'auto'
        }}
        width="150px"
        height="150px"
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid">
        <circle
          cx="50"
          cy="50"
          r="0"
          fill="none"
          stroke="#4e50fb"
          strokeWidth="2">
          <animate
            attributeName="r"
            repeatCount="indefinite"
            dur="2.5s"
            values="0;50"
            keyTimes="0;1"
            keySplines="0 0.2 0.8 1"
            calcMode="spline"
            begin="0s"/>
          <animate
            attributeName="opacity"
            repeatCount="indefinite"
            dur="2.5s"
            values="1;0"
            keyTimes="0;1"
            keySplines="0.2 0 0.8 1"
            calcMode="spline"
            begin="0s"/>
        </circle>
        <circle
          cx="50"
          cy="50"
          r="0"
          fill="none"
          stroke="#bccffa"
          strokeWidth="2">
          <animate
            attributeName="r"
            repeatCount="indefinite"
            dur="2.5s"
            values="0;50"
            keyTimes="0;1"
            keySplines="0 0.2 0.8 1"
            calcMode="spline"
            begin="-1.25s"/>
          <animate
            attributeName="opacity"
            repeatCount="indefinite"
            dur="2.5s"
            values="1;0"
            keyTimes="0;1"
            keySplines="0.2 0 0.8 1" calcMode="spline"
            begin="-1.25s"/>
        </circle>
      </svg>
    </Container>
  );

};

export default Loader;

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
`;
