import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import Breakpoints from '../../../assets/breakpoints';

const GoBack: React.FunctionComponent = () => {

  const history = useHistory();

  const clickHandler = () => history.goBack();

  return (
    <Container>
      <Button
        onClick={ clickHandler }>
        <FontAwesomeIcon
          icon={ faArrowLeft }/>
      </Button>
    </Container>
  );

};

export default GoBack;

const Container = styled.div`
  margin: 20px 0;
  
  @media screen and (min-width: ${ Breakpoints.LAPTOP }) {
    display: none;
  }
`;

const Button = styled.div`
  cursor: pointer;
  width: 56px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  color: ${ (props) => props.theme.ui.goBack.color };
  border: 1px solid ${ (props) => props.theme.ui.goBack.borderColor };
`;
