import React from 'react';

const ClockIcon: React.FunctionComponent = () => {

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.9986 18.6822C15.6887 18.6822 18.6802 15.6907 18.6802 12.0005C18.6802 8.31033 15.6887 5.31885 11.9986 5.31885C8.30838 5.31885 5.31689 8.31033 5.31689 12.0005C5.31689 15.6907 8.30838 18.6822 11.9986 18.6822Z"
        stroke="#44D1A7"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"/>
      <path
        d="M12 9.13623V11.9998L13.4318 13.4316"
        stroke="#44D1A7"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"/>
      <path
        d="M16.3 17.1065L15.9659 20.7623C15.9229 21.2383 15.7029 21.6808 15.3495 22.0024C14.996 22.3241 14.5348 22.5015 14.0569 22.4995H9.92379C9.44589 22.5015 8.98464 22.3241 8.63118 22.0024C8.27772 21.6808 8.05775 21.2383 8.01475 20.7623L7.68066 17.1065M7.69021 6.89307L8.02429 3.23725C8.06716 2.76292 8.28576 2.32175 8.63721 2.00033C8.98865 1.67891 9.44754 1.50046 9.92379 1.50002H14.076C14.5539 1.49808 15.0151 1.67546 15.3686 1.99711C15.722 2.31876 15.942 2.76128 15.985 3.23725L16.3191 6.89307"
        stroke="#44D1A7"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"/>
    </svg>
  );

};

export default ClockIcon;
