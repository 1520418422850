import { PopupState } from './reducer';
import { ReduxState } from '../store';
import { createSelector } from "@reduxjs/toolkit";

const PopupStore = (state: ReduxState) => state.popup;

export const getErrorMessage = createSelector(
  PopupStore,
  (state: PopupState) => state.error
);

export const getSuccessMessage = createSelector(
  PopupStore,
  (state: PopupState) => state.success
);
