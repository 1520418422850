import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import Breakpoints from '../../../assets/breakpoints';

interface Props {
  text: string | null;
  type: 'success' | 'error' | 'info';
  show: boolean;
}

const variants = {
  hidden: {
    opacity: 0
  },
  show: {
    opacity: 1,
    transition: {
      delayChildren: 0.5
    }
  }
};

const PopupMessage: React.FunctionComponent<Props> = ({ text, type, show }) => {

  return (
    <PopupContainer
      type={ type }
      variants={ variants }
      initial="hidden"
      animate={ show ? 'show' : 'hidden' }>
      {
        type === 'success' && (
          <>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8 12.5L11 15.5L16 9.5" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="white"
                    strokeWidth="2"/>
            </svg>
            { text && <Text>{ text }</Text> }
          </>
        )
      }
      {
        type === 'error' && (
          <>
            <IconContainer>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="white"
                      strokeWidth="2"/>
                <path d="M12 7V13M12 16.5V17" stroke="white" strokeWidth="2" strokeLinecap="round"/>
              </svg>
            </IconContainer>
            { text && <Text>{ text }</Text> }
          </>
        )
      }
      {
        (!type || type === 'info') && (
          <>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8 12.5L11 15.5L16 9.5" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="white"
                    strokeWidth="2"/>
            </svg>
            { text && <Text>{ text }</Text> }
          </>
        )
      }
    </PopupContainer>
  );

}

export default PopupMessage;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PopupContainer = styled(motion.div)<{ type: string }>`
  pointer-events: none;
  position: fixed;
  top: 25px;
  right: 25px;
  max-width: calc(100% - 50px);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 15px;
  z-index: 51;
  border-radius: 12px;
  background-color: ${ (props) => props.theme.popupMessage[props.type].backgroundColor };
  border: ${ (props) => props.theme.popupMessage[props.type].border };
  box-shadow: ${ (props) => props.theme.popupMessage[props.type].boxShadow };
  color: ${ (props) => props.theme.popupMessage[props.type].color };
  
  @media screen and (min-width: ${ Breakpoints.IPAD_PORTRAIT }) {
    min-width: 400px;
  }
`;

const Text = styled.p`
  font-weight: 300;
  font-size: .95rem;
  margin: 0 0 0 10px;
`;
