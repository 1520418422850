import { ReduxState } from '../store';
import { JobReducerInitialState } from '../../interfaces/states/job_reducer_initial_state.interface';

import { createSelector } from '@reduxjs/toolkit';

const JobStore = (state: ReduxState) => state.job;

export const getJobLoading = createSelector(
  JobStore,
  (state: JobReducerInitialState) => state.loading
);

export const getJobLoaded = createSelector(
  JobStore,
  (state: JobReducerInitialState) => state.loaded
);

export const getJobError = createSelector(JobStore, (state: JobReducerInitialState) => state.error);

export const getJob = createSelector(JobStore, (state: JobReducerInitialState) => {
  const job = state.job ? { ...state.job } : null;
  if (job) {
    if (job.description && typeof job.description === 'string') {
      try {
        const description = JSON.parse(job.description);
        job.description = description;
      } catch (e) {
        console.debug("description isn't a valid ops JSON");
      }
    }
  }
  return job;
});
