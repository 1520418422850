import React from 'react';

const CurriculumVitaeIcon: React.FunctionComponent = () => {

  return (
    <svg
      width="94"
      height="94"
      viewBox="0 0 94 94"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M68.7352 83.9924C57.6404 83.9924 46.5456 83.9924 35.4655 83.9924C34.0934 84.0217 33.2672 83.1121 32.3967 82.2465C27.9116 77.7717 23.4265 73.2969 18.8971 68.8514C18.1299 68.0885 17.7905 67.2669 17.7905 66.1959C17.8053 49.6025 17.7905 32.9944 17.8053 16.4009C17.8053 12.1609 20.9921 9.02119 25.2707 9.00652C39.4195 8.99185 53.5831 9.00652 67.732 9.00652C68.4107 9.00652 69.1041 9.07988 69.768 9.25594C73.0433 10.1069 75.2121 12.9385 75.2121 16.3863C75.2269 25.1305 75.2121 33.86 75.2121 42.6042C75.2121 53.9012 75.2121 65.1982 75.2121 76.5099C75.2121 80.1338 72.9401 82.9947 69.4877 83.8017C69.2369 83.8457 68.9861 83.919 68.7352 83.9924ZM37.5311 79.1361C37.8704 79.1361 38.136 79.1361 38.4015 79.1361C48.0947 79.1361 57.788 79.1361 67.4664 79.1361C69.4287 79.1361 70.3434 78.2265 70.3434 76.2752C70.3434 56.4394 70.3434 36.6035 70.3434 16.7531C70.3434 14.7431 69.4582 13.8481 67.4369 13.8481C53.4799 13.8481 39.5228 13.8481 25.5658 13.8481C23.5592 13.8481 22.6593 14.7284 22.6593 16.7384C22.6593 32.3048 22.6593 47.8712 22.6593 63.4377C22.6593 63.7017 22.6593 63.9658 22.6593 64.3179C23.0134 64.3179 23.3084 64.3179 23.5888 64.3179C27.3215 64.3179 31.0394 64.3179 34.7721 64.3179C36.6163 64.3179 37.5311 65.2422 37.5311 67.0615C37.5311 70.7881 37.5311 74.5293 37.5311 78.2558C37.5311 78.5199 37.5311 78.784 37.5311 79.1361ZM26.4362 69.2035C28.5018 71.2722 30.582 73.3556 32.6328 75.4096C32.6328 73.3996 32.6328 71.3162 32.6328 69.2035C30.5083 69.2035 28.4132 69.2035 26.4362 69.2035Z"
        fill="#DFE2F0"/>
      <path
        d="M46.4425 34.3874C41.9574 34.4167 38.2837 30.8075 38.2394 26.3474C38.2099 21.8139 41.8836 18.1167 46.413 18.1167C50.8834 18.1314 54.5571 21.7699 54.5866 26.2154C54.6161 30.7048 50.9572 34.358 46.4425 34.3874ZM43.1082 26.2447C43.0934 28.0786 44.554 29.5458 46.3983 29.5458C48.2277 29.5604 49.7326 28.0933 49.7326 26.274C49.7474 24.4694 48.2425 22.9583 46.4278 22.9436C44.6131 22.9436 43.1082 24.4254 43.1082 26.2447Z"
        fill="#DFE2F0"/>
      <path
        d="M46.4129 36.0452C49.0686 36.0892 51.7095 36.5293 54.1882 37.4683C55.4275 37.9378 56.652 38.5686 57.4782 39.5957C58.6733 41.1068 58.7618 43.1902 58.7176 45.1121C58.6881 46.1098 58.6143 47.1955 57.9504 47.9437C57.1684 48.8387 55.6193 48.9267 54.734 48.1198C54.1587 47.5916 53.8931 46.7993 53.8046 46.0218C53.7308 45.2442 53.8193 44.4666 53.8341 43.689C53.8488 43.3662 53.8341 43.0141 53.657 42.7353C53.5095 42.5153 53.2734 42.3686 53.0374 42.2365C51.0309 41.1655 48.685 40.9308 46.3982 40.9161C44.1999 40.9161 41.9425 41.1068 39.9803 42.0898C39.641 42.2512 39.3164 42.4566 39.1246 42.7794C38.9475 43.0875 38.9328 43.4543 38.9328 43.8064C38.9475 45.2295 39.1983 46.858 38.2393 47.9144C37.3984 48.8534 35.7312 48.8534 34.8755 47.9437C34.3444 47.3715 34.1378 46.5646 34.0493 45.787C33.7394 43.2929 34.0788 40.4466 36.0705 38.73C37.9 37.1602 40.3786 36.5146 42.7245 36.2359C43.949 36.0892 45.1884 36.0305 46.4129 36.0452Z"
        fill="#DFE2F0"/>
      <path
        d="M46.4276 55.4116C50.957 55.4116 55.5012 55.4116 60.0306 55.4116C61.4912 55.4116 62.524 56.3506 62.583 57.6857C62.642 58.9181 61.8748 59.9158 60.6502 60.1652C60.3256 60.2386 59.9716 60.2386 59.6322 60.2386C50.8242 60.2386 42.031 60.2386 33.223 60.2386C33.0312 60.2386 32.8246 60.2386 32.6328 60.2239C31.2165 60.1212 30.2427 59.1089 30.2575 57.7444C30.2722 56.3946 31.2903 55.397 32.7361 55.397C37.295 55.397 41.8687 55.4116 46.4276 55.4116Z"
        fill="#DFE2F0"/>
      <path
        d="M52.3141 72.6066C49.6879 72.6066 47.0617 72.6212 44.4503 72.5919C43.3143 72.5772 42.5028 71.873 42.2077 70.8313C41.9274 69.8483 42.2963 68.748 43.1962 68.2198C43.6388 67.9704 44.1995 67.7943 44.7159 67.7943C49.8207 67.765 54.9107 67.765 60.0155 67.7797C61.5942 67.7797 62.6269 68.8213 62.5974 70.2591C62.5679 71.6529 61.5351 72.5919 59.9712 72.6066C57.4041 72.6359 54.8664 72.6212 52.3141 72.6066C52.3141 72.6212 52.3141 72.6066 52.3141 72.6066Z"
        fill="#DFE2F0"/>
    </svg>
  );

};

export default CurriculumVitaeIcon;
