import React from 'react';
import styled from 'styled-components';

interface Props {
  margin?: string;
  children: any;
}

const Strong: React.FunctionComponent<Props> = ({ margin, children }) => {

  return (
    <Text
      margin={ margin }>
      { children }
    </Text>
  );

};

export default Strong;

const Text = styled.strong<{ margin?: string }>`
  font-size: ${ (props) => props.theme.typography.text.fontSize };
  font-weight: 700;
  font-family: ${ (props) => props.theme.typography.text.fontFamily };
  color: ${ (props) => props.theme.typography.text.color };
  margin: ${ (props) => props.margin ? props.margin : '1rem 0' };
`;
