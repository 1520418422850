import React from 'react';
import styled from 'styled-components';

interface Props {
  disabled: boolean;
  onClick?: () => void;
  children?: any;
}

const PrimaryButton: React.FunctionComponent<Props> = ({ disabled, onClick, children }) => {

  return (
    <Container
      disabled={ disabled }
      onClick={ onClick }>
      { children }
    </Container>
  );

};

export default PrimaryButton;

const Container = styled.button`
  -webkit-appearance: none;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 12px;
  width: 100%;
  max-width: 300px;
  padding: 15px;
  color: ${ (props) => props.theme.ui.buttons.primary.color };
  font-size: ${ (props) => props.theme.ui.buttons.primary.fontSize };
  font-weight: ${ (props) => props.theme.ui.buttons.primary.fontWeight };
  background-color: ${ (props) => props.theme.ui.buttons.primary.backgroundColor };
  
  &:disabled {
    cursor: not-allowed;
    opacity: .6;
  }
`;
