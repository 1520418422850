import { createSlice } from '@reduxjs/toolkit';
import { OnboardingInterface } from '../../interfaces/states/onboarding.interface';
import { STEP_THREE_SUBMIT } from './thunk';
import { STEP_ONE_SUBMIT, STEP_TWO_SUBMIT, SET_CV, UNSET_CV } from './actions';

const SLICE = 'Onboarding';

const InitialState: OnboardingInterface = {
  loading: false,
  submitted: false,
  stepOneCompleted: false,
  stepTwoCompleted: false,
  stepThreeCompleted: false,
  name: undefined,
  surname: undefined,
  phone: undefined,
  email: undefined,
  cv: undefined
};

const slice = createSlice({
  name: SLICE,
  initialState: InitialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(STEP_ONE_SUBMIT, (state,action)=>({
        ...state,
        loading: false,
        stepOneCompleted: true,
        ...action.payload
      }))
      .addCase(STEP_TWO_SUBMIT, (state,action)=>({
        ...state,
        loading: false,
        stepTwoCompleted: true,
        email: action.payload.email,
        phone: {prefix: '', number: action.payload.phone}
      }))
      .addCase(STEP_THREE_SUBMIT.pending, (state,action)=>({
        ...state,
        loading: true,
      }))
      .addCase(STEP_THREE_SUBMIT.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        stepThreeCompleted: true,
        submitted: true
      }))
      .addCase(STEP_THREE_SUBMIT.rejected, (state,action)=>({
        ...state,
        loading: false,
        stepThreeCompleted: false
      }))
      .addCase(SET_CV, (state,action)=>({
        ...state,
        loading: false,
        cv: action.payload
      }))
      .addCase(UNSET_CV, (state,action)=>({
        ...state,
        loading: false,
        cv:undefined
      }))
    
});

export default slice.reducer;
